const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-welcome',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Velkommen til hygiejnetræning. Det her er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to the first part of the hygiene training. This is the first of two modules you'll be taking today.`,
			pl: `Witaj w pierwszej części szkolenia z zasad higieny. To jest pierwszy z dwóch modułów, które dziś przejdziesz.`,
			ro: `Bun-venit la prima parte a cursului de igienă. Acesta este primul din cele două module pe care le vei face astăzi.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-points',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: `W grze otrzymujesz punkty za wykonywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.`,
			ro: `În acest joc primești puncte pentru rezolvarea sarcinilor. Cu cât faci mai puține greșeli, cu atât mai multe puncte primești.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Når du får point kan du også få stjerner. Se hvor mange point og stjerner du har i toppen af skærmen.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
			pl: `W miarę zdobywania punktów otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile masz punktów i gwiazdek.`,
			ro: `Pe lângă puncte, primești și stele. În partea de sus a ecranului poți vedea câte puncte și stele ai.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Husk, du skal have tre stjerner for at gennemføre.`,
			en: `You need to get three stars to advance to the next module.`,
			pl: `Potrzebujesz trzech gwiazdek, aby przejść do następnego modułu.`,
			ro: `Trebuie să primești trei stele pentru a trece la modulul următor.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-dressing-room',
		background: 'dressing-room',
		text: {
			da: `Inden vi går ind i produktionen, så skal vi lige forbi omklædningsrummet.`,
			en: `Before entering production, we'll swing by the changing room.`,
			pl: `Przed wejściem do obszaru produkcji zatrzymamy się w przebieralni.`,
			ro: `Înainte de a intra în zona de producție, trecem prin vestiare.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-lockers',
		background: 'dressing-room',
		text: {
			da: `Du har et skab til privat tøj og et sted til arbejdstøj.`,
			en: `You have a locker for private clothes and a place for your work clothes.`,
			pl: `Masz szafkę na twoje osobiste ubrania i miejsce na strój roboczy.`,
			ro: `Ai un dulap pentru hainele tale private și un loc pentru hainele de lucru.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-not-in-private-locker',
		layout: 's1-m3-items-not-in-private-locker',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører IKKE til i skabet til privat tøj?`,
			en: `Which of the following do NOT belong in the locker for private clothes?`,
			pl: `Jakie z następujących rzeczy NIE powinny być umieszczane w szafce na osobiste ubrania?`,
			ro: `Care dintre următoarele NU trebuie să fie în dulapul pentru hainele private?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // everyday shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // flip flops
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // white coat
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // hairnet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-not-in-work-locker',
		layout: 's1-m3-items-not-in-work-locker',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvad hører IKKE til der hvor du skal lægge eller hænge dit arbejdstøj?`,
			en: `What does NOT belong where you put or hang your work clothes?`,
			pl: `Czego NIE należy umieszczać w miejscu, gdzie kładziemy lub wieszamy strój roboczy?`,
			ro: `Ce NU trebuie să stea în locul în care îți pui sau îți atârni hainele de lucru?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // grey work clothes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // black work clogs
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // neck warmer, hat
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // peanut bar
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-items-not-in-dressing-room',
		layout: 's1-m3-items-not-in-dressing-room',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Hvad må IKKE opbevares i omklædningsrummet?`,
			en: `What is NOT allowed in the changing room?`,
			pl: `Co NIE jest dozwolone w przebieralni?`,
			ro: `Ce NU este permis în vestiar?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // knife
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // blue apron
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // socks
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // towel
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-correct-storage',
		background: 'dressing-room',
		text: {
			da: `Det er vigtigt at opbevare ting de rigtige steder. På den måde undgår du, at værktøj, tøj og skabe bliver forurenet.`,
			en: `It's important to put things the right places. That way you'll avoid getting tools, clothes, and lockers contaminated.`,
			pl: `Należy umieszczać rzeczy w przeznaczonych na nie miejscach. W ten sposób unikniesz skażenia narzędzi, ubrań i szafek.`,
			ro: `Este important să punem lucrurile la locul lor. Astfel, eviți să contaminezi instrumentele, hainele și dulapurile.`
		}
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-clothes',
		background: 'dressing-room',
		text: {
			da: `Lad os kigge nærmere på påklædning. Tryk på de ting, som er forkerte på næste billede.`,
			en: `Let's take a closer look at how to dress. On the next image, click on the things that are wrong.`,
			pl: `Zobaczmy, jak należy się ubierać. Na następnym obrazie kliknij nieprawidłowości.`,
			ro: `Să privim cu atenție modul în care trebuie să ne îmbrăcăm. În imaginea următoare, apasă pe lucrurile care sunt greșite.`
		}
	}),	
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m3-spot-errors-clothes',
		layout: 's1-m3-clothes',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 things that are wrong. Click OK when you are ready or if you can't find them all.`,
			pl: `Kliknij 4 nieprawidłowości. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			ro: `Apasă pe cele 4 lucruri care sunt greșite. Apasă pe OK când ești gata sau dacă nu găsești niciunul dintre ele.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'watch'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hair'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'buttons'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-lunch-items',
		layout: 's1-m3-lunch-items',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Du er på vej til frokost i kantinen. Hvilke madvarer må du IKKE have med i madpakken?`,
			en: `You are on your way to lunch in the cafeteria. What food are you NOT allowed to bring in your lunchbox?`,
			pl: `Idziesz na śniadanie do stołówki. Jakiego jedzenia NIE należy wnosić w pudełku na śniadanie?`,
			ro: `Te îndrepți spre prânzul de la cantină. Ce aliment NU ai voie să aduci în pachetul de prânz?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // nutella
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // rye bread
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // peanut bar
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // sandwich
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // juice
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // apple
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-nutella',
		background: 'dressing-room',
		text: {
			da: `Hvorfor må du ikke have nutella med?`,
			en: `Why are you not allowed to bring Nutella?`,
			pl: `Dlaczego nie wolno ci wnieść Nutelli?`,
			ro: `De ce nu ai voie să aduci Nutella?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Der er nødder i Nutella. Det kan gøre forbrugere syge, hvis de er allergiske`,
					en: `Nutella contains nuts. They can make consumers sick, if they have allergies`,
					pl: `Nutella zawiera orzechy, które mogą zaszkodzić konsumentom, jeśli mają alergie`,
					ro: `Nutella conține nuci. Acestea pot îmbolnăvi consumatorii, dacă au alergii`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det er usundt`, 
					en: `It's unhealthy`,
					pl: `Nie jest zdrowa`,
					ro: `Nu este sănătoasă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Man må kun spise Nutella fra kantinen`,
					en: `You are only allowed to eat Nutella from the Cafeteria`,
					pl: `Wolno jeść tylko Nutellę ze stołówki`,
					ro: `Poți mânca doar Nutella de la cantină`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fordi Nutella smager dårligt på kød`,
					en: `Because Nutella tastes bad on meat`,
					pl: `Ponieważ Nutella źle smakuje na mięsie`,
					ro: `Deoarece Nutella nu are un gust bun cu carne`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-remember',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvad skal du huske her, inden du går ud i produktionen?`,
			en: `What must you remember here, before entering production?`,
			pl: `O czym musisz pamiętać przed wejściem do obszaru produkcji?`,
			ro: `Ce trebuie să îți amintești aici înainte de a intra în zona de producție?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `At vaske hænder`,
					en: `Wash my hands`,
					pl: `Umyciu rąk`,
					ro: `Să mă spăl pe mâini`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `At rette på mit hårnet`, 
					en: `Straigthen my hairnet`,
					pl: `Wyprostować siatkę do włosów`,
					ro: `Să îmi îndrept plasa pentru păr`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det behøver du kun, hvis det sidder dårligt. Men husk at vaske hænder bagefter.`,
								en: `You only have to do that if it doesn't fit properly. But remember to wash your hands afterwards.`,
								pl: `Musisz to zrobić tylko, jeśli siatka nie leży odpowiednio. Ale pamiętaj, aby umyć później ręce.`,
								ro: `Trebuie să faci acest lucru doar dacă nu este pusă corect. Dar ține minte că trebuie să te speli pe mâini după aceea.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `At high five mine kollegaer`,
					en: `High-five my co-workers`,
					pl: `Przybić współpracownikom pią†kę`,
					ro: `Să bat palma cu colegii`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At sige det hemmelige kodeord`,
					en: `Say the secret password`,
					pl: `Powiedzieć tajne hasło`,
					ro: `Să spun parola secretă`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-good-hand-hygiene',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `En af de vigtigste regler er at have god håndhygiejne. Det handler både om at vaske hænder tit, men også om at gøre det ordentligt.`,
			en: `Proper hand hygiene is one of the most important things. That means you must wash your hands often, but also do it properly.`,
			pl: `Właściwa higiena rąk jest niezwykle ważna. To znaczy, że musisz myć ręce często, ale również prawidłowo.`,
			ro: `Igiena corectă a mâinilor este unul dintre cele mai importante lucruri. Aceasta înseamnă că trebuie să te speli pe mâini des, dar și să te speli corect.`
		},
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-washing-hands-areas',
		layout: 's1-m3-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		subtype: 'images',
		shuffleOptions: false,
		text: {
			da: `Der er især to områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Two areas are especially easy to forget, when washing hands. What are they?`,
			pl: `Podczas mycia rąk szczególnie łatwo zapomnieć o trzech częściach. Jakich?`,
			ro: `Este foarte ușor să uităm trei zone atunci când ne spălăm pe mâini. Care sunt acestea?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m3-mc-wash-hands-how-often',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvor tit skal du huske at vaske hænder?`,
			en: `How often must you wash your hands?`,
			pl: `Jak często należy myć ręce?`,
			ro: `Cât de des trebuie să te speli pe mâini?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hver anden time`,
					en: `Every second hour`,
					pl: `Co dwie godziny`,
					ro: `O dată la două ore`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Når de er blevet forurenet`, 
					en: `When they've been contaminated`,
					pl: `Kiedy uległy skażeniu`,
					ro: `Când au fost contaminate`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig! For eksempel hvis du bruger stiger, rører ved dit ansigt eller samler noget op fra gulvet.`,
								en: `Exactly! For instance if you've used a ladder, touched your face, or picked up something from the floor.`,
								pl: `Dokładnie! Na przykład po użyciu drabiny, dotknięciu twarzy lub podniesieniu czegoś z podłogi.`,
								ro: `Exact! De exemplu, dacă ai folosit o scară, dacă ți-ai atins fața sau dacă ai ridicat ceva de pe jos.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Efter pauser`,
					en: `After breaks`,
					pl: `Po przerwach`,
					ro: `După pauze`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Når jeg passerer en hygiejnesluse`,
					en: `After passing through a hygiene sluice`,
					pl: `Po przejściu przez śluzę higieniczną`,
					ro: `După ce treci printr-o ecluză de igienizare`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Hver time`,
					en: `Every hour`,
					pl: `Co godzinę`,
					ro: `La fiecare oră`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Hvert kvarter`,
					en: `Every 15 minutes`,
					pl: `Co 15 minut`,
					ro: `La fiecare 15 minute`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m3-instructions-finish',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Godt gået! Du er nu igennem første del af træningen. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til. Er du i tvivl om noget, så spørg kursuslederen eller din mester.`,
			en: `That's it, you've completed the first module. If you have three stars, you can continue to the next module. If not, you'll have to complete this module once more. If you are in doubt about anything, ask the teacher or you immediate manager.`,
			pl: `To koniec pierwszego modułu. Jeśli masz trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz ukończyć ten moduł ponownie. W razie wątpliwości zapytaj szkoleniowca lub bezpośredniego przełożonego.`,
			ro: `Gata, ai finalizat primul modul Dacă ai trei stele, poți continua la modulul următor. Dacă nu, va trebui să completezi acest modul încă o dată. Dacă ai orice fel de nelămurire, întreabă-ți profesorul sau managerul imediat.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m3-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};