const modulesData = [
	{
		id: 's1-m1',
		background: 'dressing-room',
		title: {
			da: 'Hygiejnetræning A',
			en: 'Hygiene training A',
			pl: 'Szkolenie higieniczne A',
			ro: 'Instruire igienă A'
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's1-m2',
		background: 'production',
		title: {
			da: 'Hygiejnetræning B',
			en: 'Hygiene training B',
			pl: 'Szkolenie higieniczne B',
			ro: 'Instruire igienă B'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
	{
		id: 's1-m3',
		background: 'production',
		title: {
			da: 'Gentræning A',
			en: 'Repetition A',
			pl: 'Powtórzenie A',
			ro: 'Repetiția A'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m2'],
		tasks: require('./modules/module-3').moduleTasks,
	},
	{
		id: 's1-m4',
		background: 'production',
		title: {
			da: 'Gentræning B',
			en: 'Repetition B',
			pl: 'Powtórzenie B',
			ro: 'Repetiția B'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m3'],
		tasks: require('./modules/module-4').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Hygiejnetræning',
			en: 'Hygiene training',
			pl: 'Szkolenie higieniczne',
			ro: 'Instruire igienă'
		},
		moduleIds: [
			's1-m1',
			's1-m2'
		]
	},
	{
		id: 's1-mg2',
		courseNumber: 'xxxxx',
		title: {
			da: 'Gentræning',
			en: 'Repetition',
			pl: 'Powtórzenie',
			ro: 'Repetiție'
		},
		moduleIds: [
			's1-m3',
			's1-m4'
		]
	},
];

export {
	modulesData,
	moduleGroups
};