const languagesData = [
	{
		id: 'da',
		title: {
			da: 'Dansk', 
			en: 'Dansk', // language names are always in their own language
			de: 'Dansk',
			pl: 'Dansk'
		},
		localization: 'da-DA'
	},
	{
		id: 'en',
		title: {
			da: 'English',
			en: 'English',
			de: 'English',
			pl: 'English'
		},
		localization: 'en-GB'
	},
	{
		id: 'pl',
		title: {
			da: 'Polski',
			en: 'Polski',
			de: 'Polski',
			pl: 'Polski'
		},
		localization: 'pl-PL'
	},
	{
		id: 'ro',
		title: {
			da: 'Română',
			en: 'Română',
			de: 'Română',
			pl: 'Română'
		},
		localization: 'ro-RO'
	}
];

export {languagesData};