const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-welcome',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i produktionen.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to talk about hygiene inside the production facilities.`,
			pl: `Witaj w drugiej części szkolenia z zasad higieny. Teraz porozmawiamy o higienie w zakładach produkcyjnych.`,
			ro: `Bun-venit la a doua parte a cursului de igienă. Acum vom vorbi despre igiena în unitățile de producție.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-how-to-handle-products',
		text: {
			da: `Det handler om, hvordan du skal håndtere produkter i produktionen.`,
			en: `We'll also be talking about how to handle product.`,
			pl: `Omówimy też, jak należy obchodzić się z produktami.`,
			ro: `Vom vorbi și despre cum să manipulăm produsul.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-stars',
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre.`,
			en: `Let's start! Remember, you must get three stars to complete the module.`,
			pl: `Zaczynajmy! Pamiętaj, musisz uzyskać trzy gwiazdki, aby ukończyć moduł.`,
			ro: `Să începem! Reține: trebuie să obții trei stele pentru a finaliza modulul.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-hygiene-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why must we be especially aware of hygiene in production`,
			pl: `Dlaczego higiena w obszarze produkcji jest szczególnie istotna`,
			ro: `De ce trebuie să fim foarte atenți la igienă în producție`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`,
					pl: `Ze względu na wymogi prawne`,
					ro: `Din cauza cerințelor legale`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because bad hygiene can make people sick`,
					pl: `Ponieważ zła higiena może prowadzić do chorób`,
					ro: `Deoarece igiena incorectă poate îmbolnăvi oamenii`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: `Aby zapewnić wysoką jakość`,
					ro: `Pentru a asigura calitatea înaltă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`,
					pl: `Ze wszystkich powyższych przyczyn`,
					ro: `Toate motivele de mai sus`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-production-hygiene-issues',
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at the production. On the next image, see if you can spot the things that can be problematic when it comes to hygiene.`,
			pl: `Przyjrzyjmy się produkcji. Na następnym obrazie spróbuj znaleźć problemy dotyczące higieny.`,
			ro: `Să privim cu atenție producția. În imaginea următoare, caută lucrurile care pot fi problematice când vine vorba de igienă.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-hygiene-issues',
		layout: 's1-m2-production-hygiene-issues',
		background: 'production',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`,
			pl: `Kliknij 4 problemów. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			ro: `Apasă pe cele 4 probleme. Apasă pe OK când ești gata sau dacă nu găsești niciuna dintre ele.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tray'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'wood'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'tool'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'floor'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-ham-floor',
		text: {
			da: `Der lå en skinke på gulvet i den forrige opgave – hvad skal du gøre med den?`,
			en: `There was a pork leg on the floor in the previous task - what should you do about it?`,
			pl: `W poprzednim zadaniu na podłodze leżał udziec wieprzowy – co należy z nim zrobić?`,
			ro: `În activitatea anterioară era un picior de porc pe podea - ce ar trebui să faci în legătură cu acest lucru?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Smide den ud i almindelig skraldespand`,
					en: `Toss it in a regular bin`,
					pl: `Wrzucić go do zwykłego kosza`,
					ro: `Să îl arunci într-un coș de gunoi obișnuit`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Sende den til reinspektion`, 
					en: `Send it to reinspection`,
					pl: `Wysłać go do ponownej kontroli`,
					ro: `Să îl trimiți la reinspecție`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Lægge den tilbage på båndet`,
					en: `Put it back on the belt`,
					pl: `Odłożyć go na taśmę`,
					ro: `Să îl pui din nou pe bandă`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-not-put-ham-back',
		text: {
			da: `I sidste opgave fandt du ud af, at du ikke må lægge skinken tilbage på båndet. Hvorfor må du ikke det?`,
			en: `In the previous task you found out that you can't put the pork leg back on the belt. Why isn't that allowed?`,
			pl: `W poprzednim zadaniu dowiedziałeś(-aś) się, że nie możesz odłożyć udźca wieprzowego na taśmę. Dlaczego nie jest to dozwolone?`,
			ro: `În activitatea anterioară ai aflat că nu poți pune piciorul de porc înapoi pe bandă. De ce nu este permis?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`,
					pl: `Ponieważ może on doprowadzić do skażenia następujących produktów`,
					ro: `Poate contamina produsele care urmează`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det er ligegyldigt, skinken må gerne komme tilbage på båndet`, 
					en: `It doesn't matter, the pork leg can be put back on the belt`,
					pl: `To nieistotne, udziec można odłożyć z powrotem na taśmę`,
					ro: `Nu contează, șunca poate fi pusă înapoi pe bandă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, 
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There is a risk of consumers getting sick`,
					pl: `Istnieje ryzyko, że konsumenci zachorują`,
					ro: `Există riscul de a îmbolnăvi consumatorii`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kødet er blevet sejt og kan ikke bruges`, 
					en: `The meat has become tough and cannot be used`,
					pl: `Mięso mogło stwardnieć i nie może zostać użyte`,
					ro: `Carnea a devenit dură și nu poate fi folosită`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-close-to-production',
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du ser noget, så husk at reagere.`,
			en: `You are close to our products every day, and you most likely notice things that others overlook. If you see something, remember to act on it.`,
			pl: `Każdego dnia masz bliski kontakt z naszymi produktami i zapewne zauważasz rzeczy, które inni pomijają. Jeśli coś zobaczysz, podejmij w tej sprawie działanie.`,
			ro: `Ești aproape de produsele noastre în fiecare zi și este probabil să observi lucruri pe care alte persoane le trec cu vederea. Dacă observi ceva, ține minte să acționezi.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-protect-clothes',
		layout: 's1-m2-protect-clothes',
		subtype: 'images',
		text: {
			da: `Det er vigtigt, at dit tøj ikke kommer i berøring med uemballerede produkter. Hvad skal du bruge eller gøre for at undgå det?`,
			en: `It's important that your clothes don't touch non-packaged product. What should you use or do to avoid that?`,
			pl: `Twoje ubrania nie powinny dotykać niezapakowanych produktów. Czego należy używać lub co zrobić, aby tego uniknąć?`,
			ro: `Este important ca hainele tale să nu atingă produsul neambalat. Ce ar trebui să utilizezi sau să faci pentru a evita asta?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // apron
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // sleeve protection
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // rolled up sleeves
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // short sleeves
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // plastic bags
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6 // bullet proof vest
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-contamination',
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der.`,
			en: `Now, let's look at contamination. Contamination is when things get into the products that aren't supposed to be there.`,
			pl: `Teraz omówmy kwestię skażenia. Skażenie ma miejsce, gdy do produktów przedostają się rzeczy, których tam być nie powinno.`,
			ro: `Acum hai să vorbim despre contaminare. Contaminarea are loc atunci când în produse ajung anumite lucruri care nu ar trebui să fie acolo.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-oil-on-track',
		text: {
			da: `Du ser en olieplet på båndet. Der er risiko for, at produkter er blevet forurenet. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `You spot an oil spil on the belt. There's a risk that products have been contaminated. What should you do? Place the actions below in the right order.`,
			pl: `Zauważasz olej rozlany na taśmie. Istnieje ryzyko, że produkty uległy skażeniu. Co należy zrobić? Umieść działania we właściwej kolejności.`,
			ro: `Observi o pată de ulei pe bandă. Există riscul ca produsele să fi fost contaminate. Ce trebuie să faci? Pune acțiunile de mai jos în ordinea corectă.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Tilkald mester`,
					en: `Call for the manager`,
					pl: `Wezwać kierownika`,
					ro: `Cheamă managerul`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Afvent frigivelse fra mester`,
					en: `Await release from the manager`,
					pl: `Poczekać na zielone światło od kierownika`,
					ro: `Așteaptă aprobarea managerului`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`,
					pl: `Kontynuować pracę`,
					ro: `Continuă munca`
				},
				correctOrderNumbers: [3]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-why-contamination-problem',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`,
			pl: `Nasze produkty mogą ulec skażeniu na różne sposoby. Dlaczego skażenie jest problemem?`,
			ro: `Produsele noastre pot fi contaminate în diverse feluri. De ce este contaminarea o problemă?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`,
					pl: `Skażenie może być niebezpieczne`,
					ro: `Poate fi periculoasă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can result in unhappy customers`,
					pl: `Skażenie może skutkować niezadowoleniem klientów`,
					ro: `Poate avea ca rezultat clienți nemulțumiți`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`,
					pl: `Prowadzi do większej emisji CO2`,
					ro: `Emite mai mult CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Forurening er kun et problem, hvis andre ser det`,
					en: `Contamination is only a problem if others see it`,
					pl: `Skażenie jest problemem tylko, jeśli inni je zauważą`,
					ro: `Contaminarea este o problemă doar dacă o văd și alții`
				},

			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`,
					pl: `W wyniku skażenia nasze ubrania mogą ulec zabrudzeniu`,
					ro: `Ne poate murdări hainele`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-allergies',
		layout: 's1-m2-allergies',
		subtype: 'images',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Hvad må du aldrig tage med på slagteriet - heller ikke i madpakken?`,
			en: `Contamination can be a problem for people with allergies. What are you never allowed to bring to the factory, not even in your lunchbox?`,
			pl: `Skażenie może być problemem dla osób z alergiami. Czego nigdy nie wolno wnosić do fabryki, nawet w pudełku na śniadanie?`,
			ro: `Contaminarea poate fi o problemă pentru persoanele cu alergii. Ce nu ai niciodată voie să aduci în fabrică, nici măcar în pachetul pentru prânz?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // nutella
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // peanuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // strawberries
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // shrimp
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-good-hygiene-starts-with-you',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image.`,
			pl: `Dobra higiena, a co za tym idzie – wysoka jakość – zaczyna się od ciebie. Spróbuj znaleźć problemy na następnym obrazie.`,
			ro: `Igiena bună și, astfel, calitatea înaltă, încep cu tine. Caută problemele din imaginea următoare.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-production-issues',
		layout: 's1-m2-production-issues',
		background: 'production',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`,
			pl: `Kliknij 4 problemy. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			ro: `Apasă pe cele 4 probleme. Apasă pe OK când ești gata sau dacă nu găsești niciuna dintre ele.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'drop',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'oil'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'thread'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-vermin',
		text: {
			da: `Vi skal undgå skadedyr i produktionen.`,
			en: `We must avoid getting pests in production.`,
			pl: `Do obszaru produkcji nie należy wpuszczać szkodników.`,
			ro: `Trebuie să evităm apariția dăunătorilor în producție.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m2-mc-mouse',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in production. What should you do?`,
			pl: `Widzisz mysz na podłodze zakładu produkcyjnego. Co należy zrobić?`,
			ro: `Vezi un șoarece pe podea în zona de producție. Ce trebuie să faci?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while I call for help`,
					pl: `Mieć oko na mysz i wezwać pomoc`,
					ro: `Încearcă să urmărești șoarecele cât chem ajutoare`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`, 
					en: `Try to catch the mouse yourself and then call for help if that is not possible`,
					pl: `Spróbować złapać mysz, a w razie niepowodzenia wezwać pomoc`,
					ro: `Încearcă să prinzi șoarecele singur(ă) și apoi cheamă ajutoare dacă nu reușești`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell the immediate manager after the end of the work day`,
					pl: `Powiadomić o tym kierownika na koniec dnia roboczego`,
					ro: `Spune managerului imediat după încheierea zilei de lucru`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-survey',
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på Tican. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about food safety culture at Tican. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`,
			pl: `Na koniec mamy cztery pytania na temat kultury bezpieczeństwa żywnościowego w firmie Tican. Nie ma prawidłowych ani złych odpowiedzi. Nie otrzymujesz punktów za te pytania, a twoje odpowiedzi będą anonimowe.`,
			ro: `La sfârșit, avem patru întrebări despre cultura siguranței alimentare la Tican. Nu există răspunsuri corecte și greșite. Întrebările nu îți aduc puncte, iar răspunsurile sunt anonime.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-authority-responsibility',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and responsibility`,
			pl: `Autorytet i odpowiedzialność`,
			ro: `Autoritate și responsabilitate`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har lov til og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following statement:
				<br />I have authority and responsibility to react if I spot something that can be a hazard to food safety?`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem:
				<br />Mam prawo i obowiązek, aby reagować, jeśli zauważę coś, co może zagrażać bezpieczeństwu żywnościowemu`,
			ro: `Cât de mult ești de acord cu următoarea declarație:
				<br /> Am autoritatea și responsabilitatea de a reacționa dacă observ ceva ce poate fi un pericol pentru siguranța alimentară?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-react-if-others-dont-follow-rules',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `Responds if others do not follow the rules`,
			pl: `Reaguje, jeśli inni nie przestrzegają zasad`,
			ro: `Răspunde dacă alții nu respectă regulile`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement: 
				<br />I react if I see someone who isn't following hygiene guidelines.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem: 
				<br />Reaguję, gdy zauważę kogoś, kto nie przestrzega wytycznych dotyczących higieny.`,
			ro: `Cât de mult ești de acord cu următoarea declarație: 
				<br />Reacționez dacă văd că cineva nu respectă normele de igienă.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-safety-issue-solve-or-inform',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solve/report safetyissues`,
			pl: `Rozwiązuj/zgłaszaj problemy związane z bezpieczeństwem`,
			ro: `Rezolvați/raportați problemele de siguranță`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement:
				<br />If I see a food safety issue, I'll either solve it or inform my immediate manager`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem:
				<br />Jeśli widzę problem dotyczący bezpieczeństwa żywnościowego, rozwiązuję go lub informuję bezpośredniego przełożonego.`,
			ro: `Cât de mult ești de acord cu următoarea declarație:
				<br />Dacă observ o problemă privind siguranța alimentară, o rezolv sau îmi informez managerul imediat.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m2-survey-reports-leads-to-action',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Something is being done about reports`,
			pl: `Coś się robi w sprawie raportów`,
			ro: `Se face ceva cu privire la rapoarte`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement:
				<br />If I report a food safety risk, something will be done to handle it.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem:
				<br />Jeśli zgłoszę ryzyko dotyczące bezpieczeństwa żywnościowego, zostaną podjęte kroki w tej sprawie.`,
			ro: `Cât de mult ești de acord cu următoarea declarație:
				<br />Dacă raportez un risc de siguranță alimentară, se va face ceva pentru a-l rezolva.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disgree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-finish',
		text: {
			da: `Godt gået! Du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med uddannelsen for denne gang. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module.`,
			pl: `To koniec drugiego modułu. Jeśli masz trzy gwiazdki, ukończyłeś(-aś) szkolenie z zasad higieny. Jeśli nie, musisz przejść moduł ponownie.`,
			ro: `Gata, ai finalizat al doilea modul Dacă ai obținut trei stele, ai finalizat instruirea privind igiena. Dacă nu, va trebui să reiei modulul.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};