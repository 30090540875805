const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-welcome',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal vi ud i produktionen. Husk du skal have tre stjerner for at gennemføre.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to talk about hygiene inside the production facilities. Remember, you need three stars to complete.`,
			pl: `Witaj w drugiej części szkolenia z zasad higieny. Teraz porozmawiamy o higienie w zakładach produkcyjnych. Pamiętaj, musisz uzyskać trzy gwiazdki, aby ukończyć moduł.`,
			ro: `Bun-venit la a doua parte a cursului de igienă. Acum vom vorbi despre igiena în unitățile de producție. Reține: ai nevoie de trei stele pentru a finaliza modulul.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-many-procedures',
		text: {
			da: `I produktionen er der mange procedurer, som skal overholdes. For eksempel hvordan vi fylder vogne, kar og bakker.`,
			en: `In production there a lots of procedures to maintain. For instance how to fill trolleys, big boxes, and trays.`,
			pl: `W obszarze produkcji obowiązuje wiele procedur, których należy przestrzegać. Przykładowo dotyczących tego, jak należy wypełniać wózki, skrzynie i tace.`,
			ro: `În producție există multe proceduri de menținut. De exemplu: cum se umplu cărucioarele, cutiile mari și tăvile.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-trays-and-carts',
		layout: 's1-m4-trays-and-carts',
		subtype: 'images',
		text: {
			da: `Hvilke billeder er korrekte?`,
			en: `What images are correct?`, 
			pl: `Które obrazy są prawidłowe?`,
			ro: `Care dintre imagini sunt corecte?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // cart, overfilled
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // tray, filled to just below rim
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // tray, overfilled
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // cart, filled to rim
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-overfill',
		text: {
			da: `Hvis vi overfylder vogne, kar og bakker, så er der større chance for, at kød ryger på gulvet. Men lad os fortsætte med emnet forurening.`,
			en: `If we overcrowd wagons, big boxes, and trays, there is a greater chance of meat falling to the floor. But let's continue with the topic of contamination.`,
			pl: `Jeśli przepełnimy wózki, pudełka i tacki, istnieje większe ryzyko, że mięso spadnie na podłogę. Przejdźmy do tematu skażenia.`,
			ro: `Dacă aglomerăm cărucioarele, cuvele și tăvile, carnea are șanse mai mari de a cădea pe jos. Dar hai să continuăm subiectul contaminării.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-types',
		text: {
			da: `Der er tre forskellige måder, vores produkter kan blive forurenet på: fysisk, kemisk og mikrobiologisk.`,
			en: `There are three different ways our products can become contaminated: physically, chemically, and microbiologically.`,
			pl: `Nasze produkty mogą ulec trzem typom skażenia: skażeniu fizycznemu, chemicznemu i mikrobiologicznemu.`,
			ro: `Există trei moduri diferite în care produsele noastre se pot contamina: fizic, chimic și microbiologic.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-reaction',
		text: {
			da: `Hvordan du skal reagere på forurening afhænger af, hvilken type det er. Lad os kigge nærmere på det.`,
			en: `How you should respond to contamination depends on what type it is. Let's take a closer look.`,
			pl: `To, jak należy zareagować w razie skażenia, zależy od jego typu. Przyjrzyjmy się temu.`,
			ro: `Modul în care trebuie să reacționezi la contaminare depinde de tipul acesteia. Să privim mai cu atenție.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-contamination-plastic',
		text: {
			da: `Du ser et stykke plast i noget kød på båndet. Hvad skal du gøre?`,
			en: `You see a piece of plastic in some meat on the production belt. What should you do?`,
			pl: `Widzisz kawałek plastiku w mięsie na taśmie produkcyjnej. Co należy zrobić?`,
			ro: `Vezi o bucată de plastic într-o bucată de carne de pe banda de producție. Ce trebuie să faci?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Fjern plaststykket`,
					en: `Remove the plastic piece`,
					pl: `Usunąć kawałek plastiku`,
					ro: `Să scoți bucata de plastic`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Tilkald mester`, 
					en: `Call for the manager`,
					pl: `Wezwać kierownika`,
					ro: `Să chemi managerul`
				},		
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Stands produktionen`,
					en: `Stop the production`,
					pl: `Zatrzymać produkcję`,
					ro: `Să oprești producția`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Smid kødet til destruktion`,
					en: `Discard the meat for destruction`,
					pl: `Usunąć mięso do zniszczenia`,
					ro: `Să arunci bucata de carne pentru a fi distrusă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Smid kødet til dyrefoder`,
					en: `Discard the meat for animal feed`,
					pl: `Usunąć mięso, aby zostało przerobione na karmę dla zwierząt`,
					ro: `Să arunci bucata de carne pentru a hrăni animalele`
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-glove-tip-missing',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You discover that the fingertip is missing from one of your plastic gloves. What should you do?`,
			pl: `Zauważasz, że na jednej z twoich plastikowych rękawiczek brakuje czubka palca. Co należy zrobić?`,
			ro: `Descoperi că vârful unui deget al uneia dintre mănușile tale de plastic lipsești. Ce trebuie să faci?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Informere min mester med det samme`,
					en: `Inform the manager immediately`,
					pl: `Natychmiast poinformować kierownika`,
					ro: `Informez imediat managerul`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`, 
					en: `Continue the work. I can change gloves in the next break`,
					pl: `Kontynuować pracę. Mogę zmienić rękawiczki na następnej przerwie`,
					ro: `Continui munca. Pot să schimb mănușile la următoarea pauză`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Skifte handske så jeg kan arbejde videre`,
					en: `Change gloves so I can continue working`,
					pl: `Zmienić rękawiczki, aby móc kontynuować pracę`,
					ro: `Schimb mănușile, pentru a putea continua să lucrez`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change gloves at all`,
					pl: `Nie muszę zmieniać rękawiczek`,
					ro: `Nu trebuie să schimb mănușile deloc`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-spray-meat',
		text: {
			da: `Du skyller gulvet, men kommer til at sprøjte vand op på kødet. Hvorfor er det et problem?`,
			en: `You rinse the floor, but end up splashing water on the meat. Why is it a problem?`,
			pl: `Podczas mycia podłogi niechcący wylewasz wodę na mięso. Dlaczego jest to problem?`,
			ro: `Clătești podeaua, dar stropești carnea cu apă. De ce este o problemă?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Der kan komme bakterier fra gulvet op på kødet`,
					en: `Bacteria can get from the floor onto the meat`,
					pl: `Bakterie z podłogi mogą przedostać się na mięso`,
					ro: `Pe carne pot ajunge bacterii de pe podea`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Kød kan ikke tåle vand`, 
					en: `Meat cannot tolerate water`,
					pl: `Mięso nie toleruje wody`,
					ro: `Carnea nu tolerează apa`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det er ikke et problem`,
					en: `It is not a problem`,
					pl: `To nie jest problem`,
					ro: `Nu este o problemă`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-spray-meat-handle',
		text: {
			da: `Hvad skal der ske med det kød, du er kommet til at sprøjte vand op på?`,
			en: `What should happen to the meat you have splashed water on?`,
			pl: `Co powinno się stać z mięsem, na które wylano wodę?`,
			ro: `Ce trebuie să se întâmple cu carnea pe care ai stropit-o cu apă?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Jeg skal vaske det`,
					en: `I have to wash it`,
					pl: `Muszę je umyć`,
					ro: `Trebuie să o spăl`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det skal til reinspektion`, 
					en: `It needs reinspection`,
					pl: `Mięso należy poddać ponownej kontroli`,
					ro: `Trebuie inspectată din nou`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det skal til destruktion`,
					en: `It must be destroyed`,
					pl: `Mieso należy zniszczyć`,
					ro: `Trebuie distrusă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Der skal ikke ske noget`,
					en: `Nothing should happen`,
					pl: `Nie trzeba nic zrobić`,
					ro: `Nu trebuie să se întâmple nimic`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-bottle-cleaning-agent',
		text: {
			da: `Du ser en flaske med rengøringsmiddel stå i produktionen. Hvad gør du?`,
			en: `You see a bottle of cleaning agent in production. What do you do?`,
			pl: `Widzisz w obszarze produkcji butelkę środku czyszczącego. Co robisz?`,
			ro: `Vezi un recipient cu soluție de curățare în zona de producție. Ce faci?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Lader det stå`,
					en: `Leave it be`,
					pl: `Zostaw to`,
					ro: `lasa sa fie`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Fjerner det og tilkalder så mester`, 
					en: `Remove it and then call for the manager`,
					pl: `Usuwam ją i wzywam kierownika`,
					ro: `Îl înlături și apoi chemi managerul`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt! Vi skal sikre os, at der ikke er kommet noget i produkterne.`,
								en: `Good! We have to make sure that nothing has gotten into the products.`,
								pl: `Dobrze! Musimy upewnić się, że nic nie przedostało się na produkty.`,
								ro: `Bine! Trebuie să ne asigurăm că nu a ajuns nimic în produse.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tilkalder mester`,
					en: `Call for the mamager`,
					pl: `Wzywam kierownika`,
					ro: `Chemi managerul`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Altid godt at spørge, men husk også at fjerne flasken.`,
								en: `Always good to ask, but also remember to remove the bottle.`,
								pl: `Zawsze warto pytać, ale pamiętaj też, aby usunąć butelkę.`,
								ro: `Este întotdeauna bine să întrebi, dar amintește-ți că trebuie să înlături recipientul.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fjerner det`,
					en: `Remove it`,
					pl: `Usunąć ją`,
					ro: `Îl înlături`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, men du bør også informere en leder i tilfælde af, at noget er blevet forurennet.`,
								en: `Yes. But you should also inform a manager in case something has been contaminated.`,
								pl: `Tak. Ale należy również poinformować kierownika na wypadek, gdyby coś uległo skażeniu.`,
								ro: `Da. Dar trebuie și să informezi managerul, în caz că a fost contaminat ceva.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-ask-if-in-doubt',
		text: {
			da: `Hvis du er i tvivl om, hvorvidt noget er blevet forurenet, så spørg din mester.`,
			en: `If you are in doubt as to whether something has been contaminated, ask your manager.`,
			pl: `W razie wątpliwości, czy doszło do skażenia, zapytaj kierownika.`,
			ro: `Dacă nu știi sigur dacă ceva a fost contaminat, întreabă-ți managerul.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-floor-meat',
		text: {
			da: `Hvorfor er det vigtigt, at kød, der har været på gulvet, ikke kommer op på båndet igen?`,
			en: `Why is it important that meat that has been on the floor does not come up on the production line again?`,
			pl: `Dlaczego mięso, które leżało na podłodze, nie powinno wrócić na linię produkcji?`,
			ro: `De ce este important să nu fie reintrodusă în producție carnea care a fost pe podea?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`,
					pl: `Może ono doprowadzić do skażenia następujących produktów`,
					ro: `Poate contamina produsele care urmează`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det er ligegyldigt, kødet må gerne komme tilbage på båndet`, 
					en: `It doesn't matter, the meat is welcome back on the line`,
					pl: `To bez znaczenia, mięso może wrócić na taśmę`,
					ro: `Nu contează, carnea poate fi pusă înapoi pe bandă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Der er risiko for, at forbrugerne kan blive syge`,
					en: `There is a risk that consumers may become ill`,
					pl: `Istnieje ryzyko, że konsumenci zachorują`,
					ro: `Există riscul de a îmbolnăvi consumatorii`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kødet er blevet sejt og kan ikke bruges`,
					en: `The meat has become tough and cannot be used`,
					pl: `Mięso stwardniało i nie może zostać użyte`,
					ro: `Carnea a devenit dură și nu poate fi folosită`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-almost-done',
		text: {
			da: `Du er nu næsten igennem træningen.`,
			en: `You are now almost through the training.`,
			pl: `To prawie koniec szkolenia.`,
			ro: `Aproape ai terminat instruirea.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-hygiene-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne her på slagteriet?`,
			en: `Why must we be especially aware of hygiene in the factory?`,
			pl: `Dlaczego higiena w fabryce jest szczególnie istotna?`,
			ro: `De ce trebuie să fim foarte atenți la igienă în fabrică?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`,
					pl: `Ze względu na wymogi prawne`,
					ro: `Din cauza cerințelor legale`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because poor hygiene can make people sick`,
					pl: `Ponieważ zła higiena może prowadzić do chorób`,
					ro: `Deoarece igiena incorectă poate îmbolnăvi oamenii`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: `Aby zapewnić wysoką jakość`,
					ro: `Pentru a asigura calitatea înaltă`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`,
					pl: `Ze wszystkich powyższych przyczyn`,
					ro: `Din toate motivele de mai sus`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-survey',
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på Tican. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about food safety culture at Tican. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`,
			pl: `Na koniec mamy cztery pytania na temat kultury bezpieczeństwa żywnościowego w firmie Tican. Nie ma prawidłowych ani złych odpowiedzi. Nie otrzymujesz punktów za te pytania, a twoje odpowiedzi będą anonimowe.`,
			ro: `La sfârșit, avem patru întrebări despre cultura siguranței alimentare la Tican. Nu există răspunsuri corecte și greșite. Întrebările nu îți aduc puncte, iar răspunsurile sunt anonime.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-authority-responsibility',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and responsibility`,
			pl: `Autorytet i odpowiedzialność`,
			ro: `Autoritate si responsabilitate`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har lov til og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following statement:
				<br />I have authority and responsibility to react if I spot something that can be a hazard to food safety?`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem:
				<br />Mam prawo i obowiązek, aby reagować, jeśli zauważę coś, co może zagrażać bezpieczeństwu żywnościowemu`,
			ro: `Cât de mult ești de acord cu următoarea declarație:
				<br />Am autoritatea și responsabilitatea de a reacționa dacă observ ceva ce poate fi un pericol pentru siguranța alimentară?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-react-if-others-dont-follow-rules',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `React if others do not follow the rules`,
			pl: `Reaguj, jeśli inni nie przestrzegają zasad`,
			ro: `Reacționează dacă alții nu respectă regulile`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement: 
				<br />I react if I see someone who isn't following hygiene guidelines.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem: 
				<br />Reaguję, gdy zauważę kogoś, kto nie przestrzega wytycznych dotyczących higieny.`,
			ro: `Cât de mult ești de acord cu următoarea declarație: 
				<br />Reacționez dacă văd că cineva nu respectă normele de igienă.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-safety-issue-solve-or-inform',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solve/report safetyissues`,
			pl: `Rozwiązuj/zgłaszaj problemy związane z bezpieczeństwem`,
			ro: `Rezolvați/raportați problemele de siguranță`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement:
				<br />If I see a food safety issue, I'll either solve it or inform my immediate manager.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem:
				<br />Jeśli widzę problem dotyczący bezpieczeństwa żywnościowego, rozwiązuję go lub informuję bezpośredniego przełożonego.`,
			ro: `Cât de mult ești de acord cu următoarea declarație:
				<br />Dacă observ o problemă privind siguranța alimentară, o rezolv sau îmi informez managerul imediat.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-reports-leads-to-action',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Something is being done about reports`,
			pl: `Coś się robi w sprawie raportów`,
			ro: `Se face ceva cu privire la rapoarte`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement:
				<br />If I report a food safety risk, something will be done to handle it.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem:
				<br />Jeśli zgłoszę ryzyko dotyczące bezpieczeństwa żywnościowego, zostaną podjęte kroki w tej sprawie.`,
			ro: `Cât de mult ești de acord cu următoarea declarație:
				<br />Dacă raportez un risc de siguranță alimentară, se va face ceva pentru a-l rezolva.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					ro: `Acord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					ro: `Acord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					ro: `Nici acord, nici dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					ro: `Dezacord`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					ro: `Dezacord total`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					ro: `Nu știu`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-finish',
		text: {
			da: `Godt gået! Du er nu igennem anden og sidste del af træningen. Hvis du har fået tre stjerner, er du færdig med uddannelsen for denne gang. Ellers må du tage det her modul en gang til.`,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module.`,
			pl: `To koniec drugiego modułu. Jeśli masz trzy gwiazdki, ukończyłeś(-aś) szkolenie z zasad higieny. Jeśli nie, musisz przejść moduł ponownie.`,
			ro: `Gata, ai finalizat al doilea modul Dacă ai obținut trei stele, ai finalizat instruirea privind igiena. Dacă nu, va trebui să reiei modulul.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};