import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyB2rYYS4wZvV5UiXLUebpBX-FuyIaF-uLY',
		authDomain: 'cgl-tican-test.firebaseapp.com',
		projectId: 'cgl-tican-test',
		storageBucket: 'cgl-tican-test.appspot.com',
		messagingSenderId: '491939210809',
		appId: '1:491939210809:web:25e89fdc751b7810540fe8'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDueC6-5kkd3oNIGqlkhtvZ9J1noZmGI0c',
		authDomain: 'cgl-tican-production.firebaseapp.com',
		projectId: 'cgl-tican-production',
		storageBucket: 'cgl-tican-production.appspot.com',
		messagingSenderId: '479461011166',
		appId: '1:479461011166:web:eb29509795efa60e87dc4f'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;