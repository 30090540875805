const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Velkommen til første del af hygiejnetræningen. Jeg er din mester og hjælper dig gennem spillet.`,
			en: `Welcome to the first part of the hygiene training. I'm your manager, and I'll help you through the game.`,
			pl: `Witaj w pierwszej części szkolenia z zasad higieny. Jestem twoim kierownikiem i pomogę ci podczas gry.`,
			ro: `Bun-venit la prima parte a cursului de igienă. Eu sunt managerul tău și te voi ajuta pe parcursul acestui joc.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: `W grze otrzymujesz punkty za wykonywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.`,
			ro: `În acest joc primești puncte pentru rezolvarea sarcinilor. Cu cât faci mai puține greșeli, cu atât mai multe puncte primești.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Når du får point, kan du også få stjerner. Se hvor mange point og stjerner du har på toppen af skærmen.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
			pl: `W miarę zdobywania punktów otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile masz punktów i gwiazdek.`,
			ro: `Pe lângă puncte, primești și stele. În partea de sus a ecranului poți vedea câte puncte și stele ai.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul.`,
			en: `You need to get three stars to advance to the next module.`,
			pl: `Potrzebujesz trzech gwiazdek, aby przejść do następnego modułu.`,
			ro: `Trebuie să primești trei stele pentru a trece la modulul următor.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-first-task',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Første opgave handler om den rigtige påklædning.`,
			en: `The first task is about being dressed properly.`,
			pl: `Pierwsze zadanie dotyczy odpowiedniego ubioru.`,
			ro: `Prima sarcină este aceea de a fi îmbrăcat(ă) corespunzător.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-get-dressed',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I hvilken rækkefølge skal du klæde dig på til at arbejde i produktionen? Sæt handlingerne i den rigtige rækkefølge. Tryk ok, når det er rigtigt.`,
			en: `In what order should you get dressed for work in production? Place the actions in the right order. Click OK when you think you have it.`,
			pl: `W jakiej kolejności należy ubrać się do pracy w obszarze produkcji? Umieść działania we właściwej kolejności. Kliknij OK, aby zatwierdzić odpowiedź.`,
			ro: `În ce ordine trebuie să te îmbraci pentru munca în producție? Pune acțiunile în ordinea corectă. Apasă pe OK când crezi că ai terminat.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Hårnet`,
					en: `Hairnet`,
					pl: `Siatka do włosów`,
					ro: `Plasă pentru păr`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Afsprit hænderne`,
					en: `Sanitise hands`,
					pl: `Mycie rąk`,
					ro: `Igienizează mâinile`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Bukser og kittel`,
					en: `Pants and coat`,
					pl: `Spodnie i fartuch`,
					ro: `Pantaloni și haină`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Sko eller støvler`,
					en: `Shoes or boots`,
					pl: `Buty`,
					ro: `Pantofi sau ghete`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hair-and-beard',
		layout: 's1-m1-hair-and-beard',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: `What is the correct way to use hairnet and beard snood? Click on the correct images.`,
			pl: `Jak powinno się używać siatki do włosów i brody? Kliknij właściwe obrazy.`,
			ro: `Care este modul corect de a utiliza plasa pentru păr și pentru barbă? Apasă pe imaginile corecte.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair and ears covered by hair net, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // hair, ears and beard covered
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Husk at skægbindet først skal tages på ude i gangen.`,
								en: `Remember, don't put on the beard snood until you are in the hallway.`,
								pl: `Pamiętaj, nie zakładaj siatki na brodę, dopóki nie będziesz na korytarzu.`,
								ro: `Reține: nu pune protecția pentru barbă decât când ești pe hol.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // hair sticks out, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // hair and ears covered by hair net, beard but no beardnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // ears stick out, no beard
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What kinds of jewelry are NOT allowed in the production area?`,
			pl: `Jakie rodzaje odpowiedzi NIE są dozwolone na obszarze produkcji?`,
			ro: `Ce fel de bijuterii NU sunt permise în zona de producție?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // wedding ring, smooth, no stone
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true // Nose piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // watch
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // necklace
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // glasses
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true, // ear ring
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Rigtigt. Heller ikke selvom du har et hårnet ned over.`,
								en: `That's right. And not even if you are covering it with a hairnet.`,
								pl: `Zgadza się. Nawet jeśli zakrywasz ją siatką na włosy.`,
								ro: `Exact. Și nici dacă îl/o acoperi cu plasa pentru păr.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-dressing-room',
		background: 'dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene begins in the changing room. See if you can spot the issues on the next image.`,
			pl: `Dobra higiena zaczyna się w przebieralni. Spróbuj znaleźć problemy na następnym obrazie.`,
			ro: `Igiena corectă începe în vestiar. Caută problemele din imaginea următoare.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-locker',
		layout: 's1-m1-dressing-room-locker',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in',
		text: {
			da: `Tryk på de 6 ting som er forkerte på billedet. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 6 things that are wrong on the image. Click OK when you are ready or if you can't find them all.`,
			pl: `Kliknij 6 nieprawidłowości widocznych na obrazie. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			ro: `Apasă pe cele 6 lucruri care sunt greșite în imagine. Apasă pe OK când ești gata sau dacă nu găsești niciuna dintre ele.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'knife'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hairnet'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'sandwich'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'coke'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'coat'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-locker-types',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder.`,
			en: `In the changing room there's a locker for your private clothes, and a place for work clothes. It's important to put things in the right places.`,
			pl: `W przebieralni jest szafka na twoje osobiste ubrania i miejsce na strój roboczy. Należy umieszczać rzeczy w przeznaczonych na nie miejscach.`,
			ro: `În vestiar este un dulap pentru hainele tale private și un loc pentru hainele de lucru. Este important să punem lucrurile la locul lor.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-private-locker-items',
		layout: 's1-m1-private-locker-items',
		subtype: 'images',
		text: {
			da: `Hvilke ting skal i skabet til privat tøj?`,
			en: `What goes into the locker for private clothes?`,
			pl: `Co należy włożyć do szafki na osobiste ubrania?`,
			ro: `Ce trebuie să pui în dulapul pentru hainele private?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // yellow reflex west
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // blue hairnet
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // white coat
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // neck warmer
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		taskId: 's1-m1-image-work-locker-items',
		layout: 's1-m1-private-locker-items',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-ready-production',
		text: {
			da: `Godt, så er vi ved at være klar til at gå ind i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på dén.`,
			en: `Okay, I think we're about ready to enter production. On your way there, you'll always pass through a hygiene sluice. Let's take a closer look at that.`,
			pl: `Okej, chyba jesteśmy gotowi, aby wejść do obszaru produkcji. Po drodze zawsze przechodzisz przez śluzę higieniczną. Przyjrzyjmy się jej.`,
			ro: `Bine, cred că suntem gata să intrăm în sala de producție. Pe drum, vei trece întotdeauna printr-o ecluză de igienizare. Să o privim cu atenție.`
		},
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In a hygiene sluice, you must always wash your hands. What is the correct order to do that in?`,
			pl: `W śluzie higienicznej zawsze należy umyć ręce. W jakiej kolejności trzeba to zrobić?`,
			ro: `Într-o ecluză de igienizare trebuie să îți speli întotdeauna mâinile. Care este ordinea corectă în care trebuie să faci asta?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder`,
					en: `Wash hands`,
					pl: `Myć ręce`,
					ro: `Speli mâinile`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder`,
					en: `Dry hands`,
					pl: `Suszenie rąk`,
					ro: `Usuci mâinile`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid håndklædepapir i skraldepose`,
					en: `Toss paper towels in the bin`,
					pl: `Wrzucenie papierowych ręczników do kosza`,
					ro: `Arunci prosoapele de hârtie la coș`
				},
				correctOrderNumbers: [3]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Håndhygiejne handler også om neglene. Tryk på de forkerte billeder.`,
			en: `The nails are an important part of hand hygiene. Click on the incorrect images.`,
			pl: `Paznokcie są ważną częścią higieny rąk. Kliknij niewłaściwy obraz.`,
			ro: `Unghiile sunt o parte importantă a igienei mâinilor. Faceți clic pe imaginea greșită.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // short, clean
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true// short, nail polish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // short, dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // long, dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // fake
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder? Husk at læse alle mulighederne.
				<br /><br />Vælg 1 - og husk at læse alle mulighederne:`,
			en: `After which situations must you remember to wash your hands?
				<br /><br />Choose 1 - and remember to read all the options:`,
			pl: `Po jakich sytuacjach należy pamiętać o umyciu rąk? 
				<br /><br />Wybierz 1 odpowiedź i pamiętaj, aby przeczytać wszystkie opcje:`,
			ro: `După ce situații trebuie să îți amintești să te speli pe mâini?
				<br /><br />Alege 1 - și reține că trebuie să citești toate opțiunile:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du passerer en hygiejnesluse`,
					en: `When passing a hygiene sluice`,
					pl: `Podczas mijania śluzy higienicznej`,
					ro: `Când treci printr-o ecluză de igienizare`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`, 
					en: `After breaks`,
					pl: `Po przerwach`,
					ro: `După pauze`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Hvis du samler noget op fra gulvet`,
					en: `If you pick up something from the floor`,
					pl: `Jeśli podniesiesz coś z podłogi`,
					ro: `Dacă ridici ceva de pe jos`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Efter at binde snørebånd`,
					en: `After tying my shoelaces`,
					pl: `Po zawiązaniu sznurowadeł`,
					ro: `După ce îmi leg șireturile`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Efter du har rørt ved din mund og næse`,
					en: `After touching my mouth or nose`,
					pl: `Po dotknięciu ust lub nosa`,
					ro: `După ce îmi ating gura sau nasul`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Efter brug af stiger`,
					en: `After using ladders`,
					pl: `Po używaniu drabin`,
					ro: `După ce folosesc scările`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all of the situations above`,
					pl: `We wszystkich powyższych sytuacjach`,
					ro: `În toate situațiile de mai sus`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-survey',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Inden vi går ind i produktionen er der et vigtigt spørgsmål om sygdom.`,
			en: `Before we enter the production area, we have an important question about diseases.`,
			pl: `Zanim wejdziemy do obszaru produkcji, mamy ważne pytanie na temat chorób:`,
			ro: `Înainte să intrăm în zona de producție, avem o întrebare importantă despre boli.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-inform-disease',
		shuffleOptions: false,
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		title: {
			da: `Informer om sygdom`,
			en: `Inform about illness`,
			pl: `Poinformuj o chorobie`,
			ro: ``
		},
		text: {
			da: `Er du bekendt med, at du har ansvar for at informere din mester, hvis du eller én fra din husstand får en infektionssygdom, lider af utilpashed eller lignende? Det kan for eksempel være efter udlandsrejser?`,
			en: `Are you aware of the following: It's your duty to inform your manager if you or a person in your household gets an infectious disease, suffer from ilness, or the like? For instance after having been abroad`,
			pl: `Czy wiesz, że: Twoim obowiązkiem jest poinformować kierownika, jeśli osoba w twoim gospodarstwie domowych zachoruje na chorobę zakaźną, źle się czuje  itp. na przykład po powrocie z zagranicy?`,
			ro: `Știai următoarele:	Este datoria ta de a-l informa pe manager dacă tu sau o persoană din gospodăria ta are o boală infecțioasă, dacă suferă de o boală sau ceva similar? De exemplu, după ce ai fost în străinătate.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, det er jeg klar over`,
					en: `Yes, I am aware of that`,
					pl: `Tak, mam tego świadomość`,
					ro: `Da, cunosc acest lucru`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Hvis vi ved den slags, så kan vi bedre sikre en høj hygiejnestandard.`,
								en: `Good. If we are told things like that, we can work better to ensure high hygiene standards.`,
								pl: `Dobrze. Wiedząc takie rzeczy, możemy lepiej przestrzegać wysokich standardów dotyczących higieny.`,
								ro: `Bine. Dacă ni se spun astfel de lucruri, putem lucra mai bine pentru a ne asigura că respectăm standarde înalte de igienă.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, det vidste jeg ikke`,
					en: `No, I was not aware of that`,
					pl: `Nie, nie wiedziałem(-am) o tym`,
					ro: `Nu, nu știam.`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt du siger det. Når du er færdig med træningen, så tal straks med din mester eller kursusleder og få styr på det.`,
								en: `It's a good thing you say so. When you are done with this training, immediately talk to your manager or instructor to make sure you understand the rules.`,
								pl: `Dobrze, że o tym informujesz. Gdy zakończysz to szkolenie, natychmiast porozmawiaj z kierownikiem lub szkoleniowcem, aby upewnić się, że rozumiesz zasady.`,
								ro: `Este bine că ai spus. Când termini acest training, discută imediat cu managerul sau profesorul tău pentru a te asigura că înțelegi regulile.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-finish',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Godt gået! Du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you've completed the first module. If you have three stars, you can continue to the next module. If not, you'll have to complete this module once more.`,
			pl: `To koniec pierwszego modułu. Jeśli masz trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz ukończyć ten moduł ponownie.`,
			ro: `Gata, ai finalizat primul modul Dacă ai trei stele, poți continua la modulul următor. Dacă nu, va trebui să completezi acest modul încă o dată.`
		},
	})
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};