// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const cookiesUiTexts = {
	title: {
		da: 'Vi bruger cookies',
		en: 'We use cookies',
		pl: 'Używamy plików cookie',
		ro: 'Folosim cookie-uri'
	},
	text: {
		da: `Vi bruger cookies til at forbedre din spiloplevelse. De håndterer din adgang spillet, og spillet fungerer derfor ikke uden.
			<br /><br />Tryk "OK" for at give os samtykke til at bruge cookies.
			<br /><br />Dit samtykke er gyldigt i et år. Du kan til hver en tid trække dit samtykke tilbage ved at klikke på ikonet nede i venstre hjørne.`,
		en: `We use cookies to improve your game experience. They handle your access to the game, and the game will not work without them. 
			<br /><br />Press "OK" to give us your consent to using cookies. 
			<br /><br />Your consent is valid for a year. You can at any time withdraw your consent by clicking on the icon in the bottom left corner. `,
		pl: `Używamy plików cookie, aby poprawić wrażenia z gry. Obsługują Twój dostęp do gry, dlatego bez nich gra nie działa.
			<br /><br />Naciśnij „OK”, aby wyrazić zgodę na używanie plików cookie.                                         
			<br /><br />Twoja zgoda jest ważna przez rok. W każdej chwili możesz wycofać swoją zgodę, klikając ikonę w lewym dolnym rogu.`,
		ro: 'Folosim cookie-uri pentru a vă îmbunătăți experiența de joc. Aceștia gestionează accesul tău la joc, iar jocul nu va funcționa fără ei. <br /><br />Apăsați „OK” pentru a ne da acordul dumneavoastră pentru utilizarea cookie-urilor. <br /><br />Consimțământul dvs. este valabil timp de un an. Vă puteți retrage oricând consimțământul făcând clic pe pictograma din colțul din stânga jos.'
	},
	okBtn: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		ro: 'Bine'
	},
	rejectBtn: {
		da: 'Afvis',
		en: 'Reject',
		pl: 'Odrzuć',
		ro: 'Respinge'
	}
};

module.exports = {
	cookiesUiTexts
};