// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const errorUiTexts = {
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.',
		pl: 'Błąd: nieznany błąd.',
		ro: 'Eroare: eroare necunoscută.'
	},

	/* Cookies not accepted */
	cookiesNotAccepted: {
		da: `Du skal godkende brug af cookies for at kunne logge ind.`,
		en: 'You have to accept use of cookies to log in.',
		pl: 'Aby się zalogować, musisz zaakceptować użycie plików cookie.',
		ro: 'Trebuie să acceptați utilizarea cookie-urilor pentru a vă autentifica.'
	},

	/* Create user (facilitator) */
	emptyFields: {
		da: 'Du skal udfylde alle felter.',
		en: 'Please fill out all fields.',
		pl: 'Proszę wypełnić wszystkie pola.',
		ro: 'Vă rugăm să completați toate câmpurile.'
	},
	emailExists: {
		da: 'Der findes allerede en bruger med denne email.',
		en: 'This email is already in use.',
		pl: 'Ten email jest już w użyciu.',
		ro: 'Acest email este deja folosit.'
	},
	invalidPassword: {
		da: 'Dit password skal være på mindst 8 tegn.',
		en: 'Your password has to be at least 8 characters.',
		pl: 'Twoje hasło musi mieć co najmniej 8 znaków.',
		ro: 'Parola dvs. trebuie să aibă cel puțin 8 caractere.'
	},
	noPasswordMatch: {
		da: 'Password skal være ens i begge felter.',
		en: 'Passwords do not match.',
		pl: 'Hasła nie pasują do siebie.',
		ro: 'Parolele nu se potrivesc.'
	},

	/* Login / reset password (facilitator) */
	emailNotFound: {
		da: 'Der findes ingen bruger med denne email.',
		en: 'There is no user linked to this email.',
		pl: 'Żaden użytkownik nie jest powiązany z tym e-mailem.',
		ro: 'Nu există niciun utilizator conectat la acest e-mail.'
	},
	invalidEmail: {
		da: 'Din email er ikke gyldig.',
		en: 'This email is not valid.',
		pl: 'Ten e-mail jest nieprawidłowy.',
		ro: 'Acest e-mail nu este valid.'
	},
	invalidEmailPasswordFormat: {
		da: 'Fejl. Check om din email og password er korrekte.',
		en: 'Error. Check if your email and password are correct.',
		pl: 'Błąd. Sprawdź, czy Twój adres e-mail i hasło są poprawne.',
		ro: 'Eroare. Verificați dacă adresa dvs. de e-mail și parola sunt corecte.'
	},
	
	/* Login (player) */
	emptyUserId: {
		da: 'Husk at udfylde dit medarbejder-nr.',
		en: 'Please enter your employee no.',
		pl: 'Proszę podać swój numer pracownika.',
		ro: 'Vă rugăm să introduceți numărul de angajat.'
	},
	invalidUserId: {
		da: 'Det indtastede nummer er forkert. Husk det skal være på 4-7 cifre.',
		en: 'Invalid number. Remember it must be 4-7 digits.',
		pl: 'Nieprawidłowy numer. Pamiętaj, że musi to być 4-7 cyfr.',
		ro: 'Numar invalid. Amintiți-vă că trebuie să aibă 4-7 cifre.'
	}
};

module.exports = {
	errorUiTexts
};